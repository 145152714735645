import React from "react";
// import React, { useState } from "react";
// import { Route, Switch } from "react-router-dom";
// import Home from "./pages/Home";
// import Game from "./pages/Game";
// import About from "./pages/About";
import { CssBaseline } from "@material-ui/core";

function App() {
  // const [selectedSave, setSelectedSave] = useState();
  // const [saves, setSaves] = useState([]);

  return (
    <div className="App">
      <CssBaseline />
      <div
        style={{
          textAlign: "center",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <h1>Global Football Manager</h1>
        <h3>A football management game</h3>
        <p>...coming soon</p>
      </div>
      {/* <Switch> */}
      {/* maybe /game and /about should be inside the / as they would be more children rather than siblings of / ? */}
      {/* <Route path="/" exact>
          <Home
            setSelectedSave={setSelectedSave}
            saves={saves}
            setSaves={setSaves}
          />
        </Route> */}
      {/* <Route path="/game">
          <Game
            setSelectedSave={setSelectedSave}
            selectedSave={selectedSave}
            saves={saves}
            setSaves={setSaves}
          />
        </Route> */}
      {/* <Route path="/about">
          <About />
        </Route> */}
      {/* </Switch> */}
    </div>
  );
}

export default App;
